#RelOrdemServicoPage .divPesquisa {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}

#RelOrdemServicoPage button {
  height: 45px;
  border-radius: 5px;
  border: 0;
  color: #FFF;
  background: #477ff4;
  cursor: pointer;
}

#RelOrdemServicoPage .divPesquisa span{
  font-weight: 500;
  font-size: 14px;
}

@media screen and (max-width: 600px){
  #RelOrdemServicoPage .divPesquisa .pesqEmpresa, .divPesquisa .pesqCliente, .divPesquisa .pesqDate{
    width: 100%;
  }
}

@media screen and (min-width: 601px) {
  #RelOrdemServicoPage .divPesquisa .pesqEmpresa, .divPesquisa .pesqCliente{
    width: 49%;
  }
  #RelOrdemServicoPage .divPesquisa .pesqDate{
    width: 20%;
  }
}