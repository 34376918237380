.divModalNfe {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.divModalNfe .body {
  height: 290;
  overflow: auto;
}

.divModalNfe .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #477ff4;
  color: #FFF;
  font-size: 22px;
  font-weight: 550;
}

.divModalNfe .body .divFormModal{
  display: flex;
  flex-direction: column;
  margin: 15px;
}

.divModalNfe .body .divFormModal span{
  font-size: 14px;
  font-weight: 500;
}

.divModalNfe .body .divFormModal button{
  font-size: 20px;
  padding: 0px 15px;
  height: 45px;
  margin-top: 15px;
}

.divModalNfe .divTipoEmissao {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.divModalNfe .divTipoEmissao label input {
  margin-right: 5px;
}

.divModalNfe .divForm {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.divModalNfe .divForm div span {
  font-size: 14px;
  font-weight: 500;
}

.divModalNfe .divForm button{
  width: 150px;
  margin-top: 19px;
  height: 45px;
}

.divModalNfe .divGrid{
  width: 100%;
  padding: 0px 15px;
}

.divModalNfe .divGrid .headTable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  background: #477ff4;
  padding: 10px 10px;
  color: #FFF;
  font-weight: 500;
  border-radius: 5px 5px 0px 0px;
}

.divModalNfe .divGrid .contentTable {
  width: 100%;
  min-height: 10px;
  border: 1px solid #ddd;
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;
}

.divModalNfe .divGrid .rowTable {
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  justify-content: space-between;
  padding: 10px 10px;
  color: #888;
  font-size: 14px;
  align-items: center;
}

.divModalNfe .divGrid .rowTable:hover {
  background: #f5f5f5;
}

.divModalNfe .divGrid .rowTable span button {
  height: 35px;
  width: 40px;
  border: 0px;
  border-radius: 5px;
  color: #FFF;
  margin: 0px 5px;
  cursor: pointer;
  padding: 0px;
}

.divModalNfe .divGrid .rowTable .divDesconto {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.divModalNfe .divGrid .rowTable .divDesconto button {
  margin: 0px;
}

.divModalNfe .divGrid .rowTable .divDesconto span {
  font-size: 18px !important;
}