#loginPage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

#loginPage .form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 15px 20px;
}

@media screen and (max-width: 600px) {
  #loginPage .form{
    width: 80%;
    min-height: 40vh;
  }
}

@media screen and (min-width: 601px){
  #loginPage .form{
    width: 400px;
    min-height: 250px;
  }
}

#loginPage .form span{
  margin-top: 15px;
  margin-bottom: 3px;
  font-size: 13px;
  font-weight: 500;
}

#loginPage .form button{
  color: #FFF;
  margin-top: 15px;
  padding: 8px 10px;
  border-radius: 5px;
  background: #337ab7;
  border: 0;
  width: 100%;
}