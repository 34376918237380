.PedDetalhePage {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 750px;
}

.PedDetalhePage h3 {
  text-align: center;
  margin-top: 20px;
}

.PedDetalhePage .abas {
  font-size: 15px;
  font-weight: 500;
  color: #FFF;
  background: #477ff4;
  width: 150px;
  padding: 8px 10px;
  border-radius: 5px 5px 0px 0px;
}

.PedDetalhePage .abasContent {  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #DDD;
  border-radius: 0px 5px 5px 5px;
  padding: 5px 15px;
  gap: 5px;
}

.PedDetalhePage .abasContent span {
  font-weight: 500;
  font-size: 14px;
}

.PedDetalhePage .abasContent p {
  margin-top: 2px;
  font-size: 14px;
}

.PedDetalhePage .abasContent .gridContext {
  min-width: 700px;
  overflow: auto;
  margin-bottom: 15px;
}

@media screen and (min-width: 1055px){
  .PedDetalhePage .abasContent .gridContext {
    width: 100%;
  }
}

.PedDetalhePage .abasContent .gridContext .headTable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  background: #477ff4;
  padding: 10px 10px;
  color: #FFF;
  font-weight: 500;
  border-radius: 5px 5px 0px 0px;
}

.PedDetalhePage .gridContext .contentTable {
  width: 100%;
  min-height: 10px;
  border: 1px solid #ddd;
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;
}

.PedDetalhePage .abasContent .gridContext .rowTable {
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  justify-content: space-between;
  padding: 10px 10px;
  color: #888;
  font-size: 14px;
  align-items: center;
}

.PedDetalhePage .abasContent .gridContext .rowTable:hover {
  background: #f5f5f5;
}
