.ordemServicoModalPgtos .body .contentTable {
  margin-bottom: 0px;
  border-radius: 0px;
}

.ordemServicoModalPgtos .body .footerTable {
  margin: 0 15px;
  background-color: #477ff4;
  min-height: 15px;
  border-radius: 0px 0px 5px 5px;
}

.ordemServicoModalPgtos .body .footerTable > div {
  padding: 10px 15px;
  color: #FFF;
  font-weight: 500;
  font-size: 14px;
  text-align: right;
}