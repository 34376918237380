#modalValorInicial {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#modalValorInicial .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #477ff4;
  color: #FFF;
  font-size: 22px;
  font-weight: 550;
}

#modalValorInicial .body {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

#modalValorInicial .body button {
  margin-top: 15px;
}