#pageHome .alert{
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
  border-radius: 5px;
  background-color: #e77f7f;
  padding: 15px;
  color: #FFF;
}

#pageHome .alert p{
  font-size: 14px;
  font-weight: 500;
}

#pageHome .alert span {
  font-size: 12px;
}

#pageHome .alert button {
  width: 200px;
}

#pageHome .tableAgendamentos {
  margin-top: 10px;
  border: 1px solid #DDD;
  border-radius: 5px;
  font-size: 14px;
}

#pageHome .tableAgendamentos .contentAgendamento {
  display: flex;
  flex-direction: row;
}

#pageHome .tableAgendamentos .contentAgendamento:nth-child(even) {
  background: #f1f1f1;
}

#pageHome .tableAgendamentos .contentAgendamento div button {
  margin-top: 15px;
  margin-left: 15px;
  width: 40px;
}

#pageHome .tableAgendamentos .lineAgendamento {
  flex: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

#pageHome .tableAgendamentos .lineAgendamento hr {
  width: 50%;
  margin: 0 auto;
  margin-top: 10px;
  border: 1px solid #C0C0C0;
}