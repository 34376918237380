#modalFormaPgtoBandTaxas {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#modalFormaPgtoBandTaxas .body {
  height: 290;
  overflow: auto;
}

#modalFormaPgtoBandTaxas .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #477ff4;
  color: #FFF;
  font-size: 22px;
  font-weight: 550;
}

#modalFormaPgtoBandTaxas .divForm {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

#modalFormaPgtoBandTaxas .divForm div span {
  font-size: 14px;
  font-weight: 500;
}

#modalFormaPgtoBandTaxas .divForm button{
  width: 150px;
  height: 45px;
}

#modalFormaPgtoBandTaxas .divGrid{
  width: 100%;
  padding: 0px;
}

#modalFormaPgtoBandTaxas .divGrid .headTable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  background: #477ff4;
  padding: 10px 10px;
  color: #FFF;
  font-weight: 500;
  border-radius: 5px 5px 0px 0px;
}

#modalFormaPgtoBandTaxas .divGrid .contentTable {
  width: 100%;
  min-height: 10px;
  border: 1px solid #ddd;
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;
}

#modalFormaPgtoBandTaxas .divGrid .rowTable {
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  justify-content: space-between;
  padding: 10px 10px;
  color: #888;
  font-size: 14px;
  align-items: center;
}

#modalFormaPgtoBandTaxas .divGrid .rowTable:hover {
  background: #f5f5f5;
}

#modalFormaPgtoBandTaxas .divGrid .rowTable span button {
  height: 35px;
  width: 40px;
  border: 0px;
  border-radius: 5px;
  color: #FFF;
  margin: 0px 5px;
  cursor: pointer;
  padding: 0px;
}