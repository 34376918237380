* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #ddd;
  color: #29292e;
}

body,
input,
button,
textarea {
  font: 400 16px "Roboto", sans-serif;
}

button{
  height: 38px;
}

input{
  border: 1px solid black;
}

input:read-only{
  background: #eee;
  border: 1px solid #ddd;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
::-webkit-scrollbar-thumb {
  background: #888; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 750px;
  width: 100%;
}

.contentInput {
  display: flex;
  flex-direction: column;
  padding: 15px 15px 0px 0px;
}

.form .contentInput span{
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 3px;
}

.btnSalvar {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 10px 20px;
}

.contentBtns{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.btn-orange {
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #FFF !important;
  background: #f8ba56 !important;
}

.btn-red {
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #FFF !important;
  background: #eb514c !important;
}

.btn-green {
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #FFF !important;
  background: #9dcc65 !important;
}

.btn-blue-dark {
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #FFF !important;
  background: #385e9c !important;
}

select {
  height: 45px;
  width: 100%;
  border-radius: 5px;
  padding: 4px 16px;
  font-size: 14px;
}