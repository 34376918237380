#modalInfoNfe {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

#modalInfoNfe .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #477ff4;
  color: #FFF;
  font-size: 22px;
  font-weight: 550;
}

#modalInfoNfe .body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 15px;
}

#modalInfoNfe .body div {
  background: #F0F0F0;
  padding: 5px 10px;
  border-radius: 5px;
}

#modalInfoNfe .body div #divCopy{
  margin-left: 7px;
  background: #898787;
  color: #FFF;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.5s;
}


#modalInfoNfe .body .divBtns {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

#modalInfoNfe .body div button {
  margin: 10px 0px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

#modalInfoNfe .body span {
  font-size: 14px;
  font-weight: 500;
}

#modalInfoNfe .body p {
  font-size: 14px;
}