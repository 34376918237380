#produtosFormPage .contentInput .flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

#produtosFormPage .contentInput .flexRow button {
  height: 45px;
  width: 55px;
}