.modal {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #477ff4;
  color: #FFF;
  font-size: 22px;
  font-weight: 550;
}

.modal .headTable {
  margin: 15px 15px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #477ff4;
  padding: 10px 10px;
  color: #FFF;
  font-weight: 500;
  border-radius: 5px 5px 0px 0px;
}

.modal .contentTable {
  margin: 0px 15px 15px 15px;
  border: 1px solid #ddd;
  min-height: 300px;
  border-radius: 0px 0px 5px 5px;
}

.modal .rowTable {
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  justify-content: space-between;
  padding: 10px 10px;
  color: #888;
  font-size: 14px;
  align-items: center;
}

.modal .rowTable:hover {
  background: #f5f5f5;
}

.modal .rowTable span{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.modal .rowTable span button {
  font-size: 14px;
  height: 30px;
  width: 30px;
  border: 0px;
  border-radius: 5px;
  color: #FFF;
  cursor: pointer;
}

.modal .body .divFormModal{
  display: flex;
  flex-direction: row;
  margin: 10px 15px;
  gap: 10px;
}

.modal .body .divFormModal button{
  font-size: 20px;
  padding: 0px 15px;
}

.modal .body .btnNovo{
  font-size: 20px;
  padding: 5px 10px;
}

.modal .body .info {
  margin: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.modal .body .info h4 {
  font-weight: 500;
}