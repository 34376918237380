#divFormaPgtoForm .contentInput .btnAdd {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 180px;
}

#divFormaPgtoForm .contentInput .gridContext .contentTable .rowTable .divBtns{
  width: 200px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}