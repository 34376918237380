#OrdemServicoFormPage button {
  height: 45px;
  padding: 0px 15px;
  border-radius: 5px;
  border: 0;
  color: #FFF;
  background: #477ff4;
  cursor: pointer;
}

#OrdemServicoFormPage .form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 750px;
  width: 100%;
}

#OrdemServicoFormPage .contentInput {
  display: flex;
  flex-direction: column;
  padding: 15px 15px 0px 0px;
  justify-content: center;
}

#OrdemServicoFormPage .contentInput textarea {
  border-radius: 5px;
  resize: none;
  height: 120px;
  padding: 10px;
}

#OrdemServicoFormPage .form .contentInput span{
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 3px;
}

#OrdemServicoFormPage .btnSalvar {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 10px 20px;
}

#OrdemServicoFormPage .contentBtns{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

#OrdemServicoFormPage .headerAbas {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

#OrdemServicoFormPage .abas {
  font-size: 15px;
  font-weight: 500;
  width: 150px;
  padding: 8px 10px;
  border-top: 1px;
  border-right: 1px;
  border-left: 1px;
  border-bottom: 0px;
  border-color: #DDD;
  border-style: solid;
  border-radius: 5px 5px 0px 0px;
  cursor: pointer;
}

#OrdemServicoFormPage .selected {
  border: 0px;
  color: #FFF;
  background: #477ff4;
}

#OrdemServicoFormPage .abasContent {  
  border: 1px solid #DDD;
  border-radius: 0px 5px 5px 5px;
  min-height: 150px;
  padding: 0px 15px;
}

#OrdemServicoFormPage .form .infoCliente {
  width: 100%;
  border: 1px solid #DDD;
  margin-top: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
}

#OrdemServicoFormPage .form .infoCliente span {  
  padding: 10px;
  display: flex;
  flex-direction: column;
}

#OrdemServicoFormPage .abasContent .form .contentInput p {
  font-weight: 500;
  font-size: 18px;
  color: #7e7e7e;
  margin-top: 15px;
}

#OrdemServicoFormPage .gridContext {
  min-width: 700px;
  overflow: auto;
  margin-bottom: 15px;
}

@media screen and (min-width: 1055px){
  #OrdemServicoFormPage .gridContext {
    width: 100%;
  }
}

#OrdemServicoFormPage .gridContext .headTable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  background: #477ff4;
  padding: 10px 10px;
  color: #FFF;
  font-weight: 500;
  border-radius: 5px 5px 0px 0px;
}

#OrdemServicoFormPage .gridContext .contentTable {
  width: 100%;
  min-height: 10px;
  border: 1px solid #ddd;
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;
}

#OrdemServicoFormPage .gridContext .rowTable {
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  justify-content: space-between;
  padding: 10px 10px;
  color: #888;
  font-size: 14px;
  align-items: center;
}

#OrdemServicoFormPage .gridContext .rowTable:hover {
  background: #f5f5f5;
}

#OrdemServicoFormPage .gridContext .rowTable span button {
  height: 35px;
  width: 40px;
  border: 0px;
  border-radius: 5px;
  color: #FFF;
  margin: 0px 5px;
  cursor: pointer;
  padding: 0px;
}

#OrdemServicoFormPage .divDesconto span {
  font-size: 18px !important;
}

#OrdemServicoFormPage .btnSalvar {
  height: 40px;
}