#OrdemServicoPage .divPesquisa {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}

#OrdemServicoPage button {
  height: 45px;
  border-radius: 5px;
  border: 0;
  color: #FFF;
  background: #477ff4;
  cursor: pointer;
}

#OrdemServicoPage .divPesquisa span{
  font-weight: 500;
  font-size: 14px;
}

@media screen and (max-width: 600px){
  #OrdemServicoPage .divPesquisa .pesqEmpresa, .divPesquisa .pesqCliente, .divPesquisa .pesqDate{
    width: 100%;
  }
}

@media screen and (min-width: 601px) {
  #OrdemServicoPage .divPesquisa .pesqEmpresa, .divPesquisa .pesqCliente{
    width: 49%;
  }
  #OrdemServicoPage .divPesquisa .pesqDate{
    width: 20%;
  }
}

#OrdemServicoPage .gridContext {
  min-width: 800px;
  overflow: auto;
}

#OrdemServicoPage .gridContext .headTable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  background: #477ff4;
  padding: 10px 10px;
  color: #FFF;
  font-weight: 500;
  border-radius: 5px 5px 0px 0px;
}

#OrdemServicoPage .gridContext .contentTable {
  width: 100%;
  border: 1px solid #ddd;
  min-height: 300px;
  border-radius: 0px 0px 5px 5px;
}

#OrdemServicoPage .gridContext .rowTable {
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  justify-content: space-between;
  padding: 10px 10px;
  color: #888;
  font-size: 14px;
  align-items: center;
}

#OrdemServicoPage .gridContext .rowTable:hover {
  background: #f5f5f5;
}

#OrdemServicoPage .gridContext .rowTable span button {
  height: 35px;
  width: 40px;
  border: 0px;
  border-radius: 5px;
  color: #FFF;
  margin: 0px 5px;
  cursor: pointer;
  margin: 0px 3px;
}

#OrdemServicoPage .pagination {
  float: right;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border: 1px solid #DDD;
  border-radius: 5px;
  width: 120px;
  margin-top: 20px;
}

#OrdemServicoPage .pagination p {
  margin: 0px 8px;
  font-size: 15px;
  font-weight: 500;
}