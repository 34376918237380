#caixaPage {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#caixaPage .btn-xl {
  /* margin: 0 auto; */
  height: 60px;
  width: 200px;
}


#caixaPage .divBtnContent {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}