.alerta {
  position: fixed;
  right: -465px;
  top: 15px;
  width: 450px;
  min-height: 70px;
  z-index: 9999;
  border-radius: 5px;
  padding: 10px;
  color: #FFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  transition: right 0.5s;
}

.alerta-success {
  background-color: #9dcc65;
}

.alerta-danger {
  background-color: #eb514c;
}

.alerta-white {
  background-color: #FFF;
  color: #8b8b9b;
}

.bgFaixa{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #477ff4;
  max-height: 25vh;
  min-height: 180px;
  z-index: 1;
}

#divMenu nav h3{
  cursor: pointer;
}

#divMenu nav #menuLogout {
  border: 1px solid #ddd;
  background: #f5f5f5;
  padding: 10px 5px;
  margin: 0px 25px 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  height: 0px;
  opacity: 0;
  transition: height 0.5s, opacity 0.5s;
  overflow: hidden;
}

#divMenu nav .contentBars{
  width: 100%;
  text-align: right;
  padding: 0px 15px;
  font-size: 20px;
  margin-bottom: 15px;
  cursor: pointer;
}

#divMenu nav .content {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  width: 100%;
  gap: 10px;
}

#divMenu nav .content span {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: font-size 0.3s;
}

#divMenu nav .content span:hover {
  font-size: 16px;
}

#divMenu nav .content span.selected {
  font-size: 16px;
  color: #477ff4;
}

#divMenu nav #menuLogout span {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

#divMenu nav #menuLogout button {
  font-size: 12px;
  width: 80%;
  border-radius: 5px;
  padding: 5px 10px;
  color: #FFF;
  background: #477ff4;
  border: 0px;
  cursor: pointer;
}

@media screen and (min-width: 601px){
  #divMenu nav .contentBars{
    display: none;
  }

  #divMenu{
    position: fixed;
    width: 20%;
    z-index: 2;
  }

  #divMenu nav {
    padding: 20px 5px;
    text-align: center;
    margin-top: 50px;
    background: #FFF;
    border-radius: 0 8px 0 0;
    height: calc(100vh - 50px);
    overflow: auto;
  }
  
  main {
    opacity: 1;
    position: fixed;
    width: 80%;
    padding: 20px;
    z-index: 2;
    right: 0;
    top: 60px;
    height: 100%;
  }

  main .contentBars{
    display: none;
  }
}

@media screen and (max-width: 600px) {
  #divMenu{
    position: fixed;
    width: 90%;
    opacity: 0;
    z-index: 3;
    transition: width 0.5s, opacity 0.5s;
  }

  #divMenu nav {
    padding: 20px 5px;
    text-align: center;
    margin-top: 20px;
    background: #FFF;
    border-radius: 0 8px 8px 0;
    height: calc(100vh - 40px);
    overflow: auto;
    box-shadow: 5px 5px 15px #aaa;
  }
  
  main {
    position: fixed;
    width: 100%;
    padding: 10px;
    z-index: 2;
    right: 0;
    top: 30px;
    height: 100%;
  }

  main .contentBars{
    margin-bottom: 15px;
    font-size: 20px;
    color: #FFF;
  }
}

main h1{
  color: #FFF;
}

main .context{
  background: #FFF;
  padding: 15px 2px 15px 15px;
  width: 100%;
  height: calc(100% - 90px);
  border-radius: 8px;
  margin-top: 15px;
}

main .context .content {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0px 15px 0px 0px;
}

.iconUser{
  margin-left: 5px;
  cursor: pointer;
  transition: transform 0.3s;
}

#divMenu .content #subRelatorios {
  border: 1px solid #ddd;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 0px;
  opacity: 0;
  transition: height 0.5s, opacity 0.5s;
  overflow: hidden;
}