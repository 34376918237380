#divServicoForm .tableGrid {
  flex-direction: row;
  display: flex;
  width: 100%;
  margin: 15px 0px;
}

#divServicoForm .tableGrid .tableContent {
  background-color: #f0f0f0;
  margin: 10px;
  padding: 15px;
  border-radius: 5px;
  flex: 1;
}

#divServicoForm .tableGrid .tableContent .tableContentForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

#divServicoForm .tableGrid .tableContent .headTable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
  background: #477ff4;
  padding: 10px 10px;
  color: #FFF;
  font-weight: 500;
  border-radius: 5px 5px 0px 0px;
}

#divServicoForm .tableGrid .contentTable {
  width: 100%;
  min-height: 10px;
  border: 1px solid #ddd;
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;
}

#divServicoForm .tableGrid .tableContent .rowTable {
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  justify-content: space-between;
  padding: 10px 10px;
  color: #888;
  font-size: 14px;
  align-items: center;
}

#divServicoForm .tableGrid .tableContent .rowTable:hover {
  background: #f5f5f5;
}

#divServicoForm .tableGrid .tableContent .rowTable span button {
  height: 35px;
  width: 40px;
  border: 0px;
  border-radius: 5px;
  color: #FFF;
  margin: 0px 5px;
  cursor: pointer;
  padding: 0px;
}